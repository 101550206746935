import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { Buffer } from 'buffer';
// import { String, StringBuilder } from 'typescript-string-operations';
import { allURLS } from './allURL';

const crypto = require('crypto-browserify');

@Injectable({
  providedIn: 'root',
})
export class CryptoService {
  encryptSecretKey = allURLS['ENCRYPTSECRETKEY'];
  chatEncryptionSecretKey = allURLS['CHATENCRYPTIONKEY'];
  // ivParameterSpec: StaticRange;
  KEY: any = [];
  IV: any = [];
  CHATKEY: any = [];
  CHATIV: any = [];
  // encodedUuid: string = null;
  // encryptedString: string = null;
  // mdString: string = null;
  encodedUuid: string = '';
  encryptedString: string = '';
  mdString: string = '';

  constructor() {}

  //encrypting the payload body.
  getEncryptedBody(cognitoId: string, plainText: string) {
    this.createKeys(cognitoId);
    this.encryptedString = this.encrypt(plainText);
    return this.encryptedString;
  }

  //decripting the payload body.
  getDecryptedBody(cognitoId: string, encryptedText: string) {
    this.createKeys(cognitoId);
    return this.decrypt(encryptedText);
  }

  //converting congnitoId in base64 format.
  getBase64String(plainText: string) {
    return Buffer.from(plainText.toString()).toString('base64');
  }

  //create MD5 hash using congitoId and encryptSecretKey.
  createKeys(cognitoId: String) {
    var uuidSimple = cognitoId.toString().replace(/-/g, '');
    let finalKey = uuidSimple + this.encryptSecretKey;
    this.mdString = crypto
      .createHash('sha256')
      .update(finalKey, 'utf8')
      .digest('hex');
    this.KEY = this.mdString.substring(0, 32);
    this.IV = this.mdString.substring(32, 64).substring(0, 16);
    this.encodedUuid = Buffer.from(cognitoId.toString()).toString('base64');
  }

  //converting text to Encryption.
  encrypt(textToEncrypt: string) {
    const iv = Buffer.from(this.IV, 'utf8');
    const key = Buffer.from(this.KEY, 'utf8');
    const cipher = crypto.createCipheriv('aes-256-gcm', key, iv);
    const encrypted = Buffer.concat([
      cipher.update(textToEncrypt, 'utf8'),
      cipher.final(),
    ]);
    const tag = cipher.getAuthTag();
    return Buffer.concat([encrypted, tag]).toString('base64');
  }

  //converting text to Decryption.
  decrypt(textToDecrypt: string) {
    const encryptedData = Buffer.from(textToDecrypt, 'base64');
    const tagLength = 16;
    const tag = encryptedData.slice(-tagLength);
    const encrypted = encryptedData.slice(0, -tagLength);
    const decipher = crypto.createDecipheriv('aes-256-gcm', this.KEY, this.IV);
    decipher.setAuthTag(tag);
    const decrypted =
      decipher.update(encrypted, 'utf8', 'utf8') + decipher.final('utf8');
    return decrypted;
  }

  //decripting the payload body.
  getEncryptedChat(text: string) {
    this.createChatKeys();
    return this.encryptChat(text);
  }

  //decripting the payload body.
  getDecryptedChat(encryptedText: string) {
    this.createChatKeys();
    return this.decryptChat(encryptedText);
  }

  createChatKeys() {
    let finalKey = this.encryptSecretKey;
    this.mdString = crypto
      .createHash('sha256')
      .update(finalKey, 'utf8')
      .digest('hex');
    this.KEY = this.mdString.substring(0, 32);
    this.IV = this.mdString.substring(32, 64).substring(0, 16);
    // this.encodedUuid = Buffer.from(cognitoId.toString()).toString('base64');
  }

  //converting text to Decryption.
  decryptChat(textToDecrypt: string) {
    const encryptedData = Buffer.from(textToDecrypt, 'base64');
    const tagLength = 16;
    const tag = encryptedData.slice(-tagLength);
    const encrypted = encryptedData.slice(0, -tagLength);
    const decipher = crypto.createDecipheriv('aes-256-gcm', this.KEY, this.IV);
    decipher.setAuthTag(tag);
    const decrypted =
      decipher.update(encrypted, 'utf8', 'utf8') + decipher.final('utf8');
    return decrypted;
  }

  //converting text to Decryption.
  encryptChat(text: string) {
    const iv = Buffer.from(this.IV, 'utf8');
    const key = Buffer.from(this.KEY, 'utf8');
    const cipher = crypto.createCipheriv('aes-256-gcm', key, iv);
    const encrypted = Buffer.concat([
      cipher.update(text, 'utf8'),
      cipher.final(),
    ]);
    const tag = cipher.getAuthTag();
    return Buffer.concat([encrypted, tag]).toString('base64');
  }
  //decripting the local storage.

  createLocalStorageKeys(encryptionKey) {
    let finalKey = encryptionKey;
    let hash = crypto
      .createHash('sha256')
      .update(finalKey, 'utf8')
      .digest('hex');

    let key = hash.substring(0, 32);
    let iv = hash.substring(32, 64).substring(0, 16);

    return { key, iv };
  }
  encryptedLocalStorage(text: string) {
    const chatEncryptionSecretKey =
      'asdfghjklqwertyuiopzxcvbnmasdfgh@YOLOH.InSuRAnCE';
    const keyData = this.createLocalStorageKeys(chatEncryptionSecretKey);
    const iv = Buffer.from(keyData.iv, 'utf8');
    const key = Buffer.from(keyData.key, 'utf8');
    const cipher = crypto.createCipheriv('aes-256-gcm', key, iv);
    const encrypted = Buffer.concat([
      cipher.update(text, 'utf8'),
      cipher.final(),
    ]);
    const tag = cipher.getAuthTag();
    return Buffer.concat([encrypted, tag]).toString('base64');
  }

  //decripting the local storage .
  decryptedLocalStorage(encryptedText: string) {
    const chatEncryptionSecretKey =
      'asdfghjklqwertyuiopzxcvbnmasdfgh@YOLOH.InSuRAnCE';
    const keyData = this.createLocalStorageKeys(chatEncryptionSecretKey);
    try {
      const encryptedData = Buffer.from(encryptedText, 'base64');
      const tagLength = 16;
      const tag = encryptedData.slice(-tagLength);
      const encrypted = encryptedData.slice(0, -tagLength);
      const decipher = crypto.createDecipheriv(
        'aes-256-gcm',
        keyData.key,
        keyData.iv
      );
      decipher.setAuthTag(tag);
      const decrypted =
        decipher.update(encrypted, 'utf8', 'utf8') + decipher.final('utf8');
      return decrypted;
    } catch (error) {
      console.error('Decryption error:', error);
      return '';
    }
  }
}
