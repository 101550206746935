<div class="customer-details-container">
    <h5 class="header-text font-family-NB" *ngIf="profileDetails" fxLayout fxLayoutGap="5px">{{profileDetails.name}}
        Profile
    </h5>
    <div class="customer" fxLayout fxLayout.lg="row" fxLayout.md="row" fxLayout.xs="column"
        fxLayoutAlign="space-between" fxLayoutGap.xs="20px">
        <div fxLayout fxLayout="column" fxLayout.xs="column" fxFlex="100%" fxFlex.sm="100%" fxFlex.xs="100%">
            <nav mat-tab-nav-bar fxLayout="row">
                <a mat-tab-link fxLayout fxLayout="row" fxLayout.xs="column" fxFlex="10%" *ngFor="let link of links"
                    [ngClass]="activeLink == link ? 'activeLink' : 'link' " (click)="activeTab(link)"
                    [active]="activeLink == link"> {{link}} </a>
            </nav>
            <div class="container mt-3" fxLayout fxLayout.xs="row" fxLayoutAlign="center" fxLayoutGap="5px"
                fxLayoutGap.xs="0">
                <mat-spinner style="margin:0 auto;" *ngIf="isLoading" [diameter]="50"></mat-spinner>
                <div class="user-list" *ngIf="!isLoading" fxLayout fxLayout="row" fxFlex="100%" fxLayoutGap="10px">
                    <!-- Profile Tab -->
                    <div fxLayout fxLayout="column" fxFlex="100%" fxLayoutGap="30px"
                        *ngIf="activeLink == 'Profile' && profileDetails">
                        <mat-card fxLayout="column" fxFlex="100%">
                            <mat-card-header fxLayout fxLayout="row" fxLayoutAlign="start">
                                <mat-card-title fxLayout="row">
                                    <div class="header-text"> Profile Details</div>
                                </mat-card-title>
                            </mat-card-header>
                            <mat-card-content fxLayout fxLayout="row" fxLayoutAlign="space-between">
                                <table fxLayout fxLayout="column" fxFlex="100%" fxLayoutGap="10px">
                                    <tbody fxLayout fxLayout="column" fxFlex="100%" fxLayoutGap="10px">
                                        <div fxLayout="row" fxLayoutAlign="space-around">
                                            <tr fxLayout="column" fxFlex="30%">
                                                <th>Name</th>
                                                <td>{{profileDetails.name}}</td>
                                            </tr>
                                            <tr fxLayout="column" fxFlex="30%">
                                                <th>Mobile</th>
                                                <td>{{profileDetails.phoneNumber}}</td>
                                            </tr>
                                            <tr fxLayout="column" fxFlex="30%" class="clearfix" style="clear: both;">
                                                <th>D.O.B</th>
                                                <td>{{profileDetails.dob | date:'MM-dd-YYYY'}}</td>
                                            </tr>
                                        </div>
                                        <div fxLayout="row" fxLayoutAlign="space-around">
                                            <tr fxLayout="column" fxFlex="30%">
                                                <th>Home Address</th>
                                                <td>{{profileDetails.address ? profileDetails.address : '-' }}</td>
                                            </tr>

                                            <tr fxLayout="column" fxFlex="30%">
                                                <th>City</th>
                                                <td>{{profileDetails.city ? profileDetails.city : '-'}}</td>
                                            </tr>
                                            <tr fxLayout="column" fxFlex="30%">
                                                <th>State</th>
                                                <td>{{profileDetails.state? profileDetails.state : '-'}}</td>
                                            </tr>
                                            <!-- <tr fxLayout="column" fxFlex="30%">
                                                    <th>Postal Code</th>
                                                    <td>{{address[2]}}</td>
                                                </tr> -->
                                        </div>
                                        <!-- <div fxLayout="row" fxLayoutAlign="space-around">

                                                <tr fxLayout="column" fxFlex="30%">
                                                    <th>State</th>
                                                    <td>{{profileDetails.state}}</td>
                                                </tr>
                                                <tr fxLayout="column" fxFlex="30%" class="clearfix" style="clear: both;">
                                                    <th>Country</th>
                                                    <td>{{address[1]}}</td>
                                                </tr>
                                                <tr fxLayout="column" fxFlex="30%"  class="clearfix" style="clear: both;">

                                                </tr>
                                            </div> -->
                                        <div fxLayout="row" fxLayoutAlign="space-around">

                                            <tr fxLayout="column" fxFlex="30%">
                                                <th>Married or Spousal Equivalent</th>
                                                <td>{{profileDetails.maritalStatus == 0 ? 'No' :'Yes'}}</td>
                                            </tr>
                                            <tr fxLayout="column" fxFlex="30%">
                                                <th>Kids</th>
                                                <td>{{profileDetails.hasKids == 0 ? 'No' : 'Yes'}}</td>
                                            </tr>
                                            <tr fxLayout="column" fxFlex="30%" class="clearfix" style="clear: both;">
                                                <th>Pets</th>
                                                <td>No</td>
                                                <!-- <td>{{profileDetails.hasPets == 0 ? 'No' : 'Yes'}}</td> -->
                                            </tr>

                                        </div>
                                        <div fxLayout="row" fxLayoutAlign="space-around">

                                            <tr fxLayout="column" fxFlex="30%">
                                                <th>Can you tell me about your owned assets ?</th>
                                                <td>{{profileDetails.assetsOwned}}</td>
                                            </tr>
                                            <tr fxLayout="column" fxFlex="30%">
                                                <th>Active Insurances</th>
                                                <td>{{profileDetails.policiesOwned}}</td>
                                            </tr>
                                            <tr fxLayout="column" fxFlex="30%" class="clearfix" style="clear: both;">

                                            </tr>

                                        </div>
                                    </tbody>
                                </table>
                            </mat-card-content>
                            <mat-card-footer fxLayout fxLayout="row" fxLayoutAlign="end">
                            </mat-card-footer>
                        </mat-card>
                        <mat-card class="example-container mb-5" fxLayout fxLayout="column">
                            <mat-card-header fxLayout fxLayout="row" fxLayoutAlign="start">
                                <mat-card-title fxLayout="row">
                                    <div class="header-text">Family Details</div>
                                </mat-card-title>
                            </mat-card-header>
                            <mat-card-content class="mb-1" fxLayout fxLayout="row" fxFlex="100%" fxLayoutAlign="start">
                                <div fxLayout fxLayout="column" fxFlex="100%"
                                    class="mat-elevation-z8  font-family-NSB mb-4">
                                    <table *ngIf="!isLoading" mat-table [dataSource]="family_dataSource" matSort
                                        (matSortChange)="sortFamilyData()">
                                        <!--- Note that these columns can be defined in any order.
                                              The actual rendered columns are set as a property on the row definition" -->

                                        <!-- profilePic Column -->
                                        <!-- <ng-container matColumnDef="profilePic">
                                            <th mat-header-cell *matHeaderCellDef>Profile</th>
                                            <td mat-cell *matCellDef="let row">
                                                <img [src]="row.profilePic && row.profilePic!='' ? row.profilePic :
                                                AssetsURL+'/img/User_Profile_Icon.png'"
                                                    class="rounded-circle" width="40px" height="40px" />
                                            </td>
                                        </ng-container> -->

                                        <!-- Name Column -->
                                        <ng-container matColumnDef="name">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                                            <td mat-cell *matCellDef="let row">{{ row.name }}</td>
                                        </ng-container>


                                        <!-- Active Policies Column -->
                                        <ng-container matColumnDef="dob">
                                            <th mat-header-cell *matHeaderCellDef>Date Of Birth</th>
                                            <td mat-cell *matCellDef="let row">{{ getDob(row.dob) }}</td>
                                        </ng-container>

                                        <!-- Upcoming Renewals Column -->
                                        <ng-container matColumnDef="relationship">
                                            <th mat-header-cell *matHeaderCellDef>Relationship</th>
                                            <td mat-cell *matCellDef="let row">{{ row.relationship }}</td>
                                        </ng-container>
                                        <tr class="mat-row" *matNoDataRow>
                                            <td [colSpan]="family_displayedColumns.length" class="mat-cell text-center">
                                                No data found</td>
                                        </tr>

                                        <!-- Action Column -->
                                        <!-- <ng-container matColumnDef="action">
                                                <th mat-header-cell *matHeaderCellDef>Action</th>
                                                <td mat-cell *matCellDef="let row">
                                                    <button (click)="deleteFamilyMember(row)" title="Delete"
                                                        class="btn btn-chat btn-sm col-md-2 mr-2">
                                                        <mat-icon >delete</mat-icon>
                                                    </button>
                                                    <button (click)="editFamilyMember(row)" title="Edit"
                                                        class="btn btn-chat btn-sm col-md-2 mr-2">
                                                        <mat-icon >edit</mat-icon>
                                                    </button>
                                                </td>
                                            </ng-container> -->

                                        <tr class="font-family-NB" mat-header-row
                                            *matHeaderRowDef="family_displayedColumns; sticky:true"></tr>
                                        <tr class="font-family-NSB" mat-row
                                            *matRowDef="let row; columns: family_displayedColumns"></tr>
                                    </table>
                                    <!-- <mat-paginator [ngClass]="!isLoading ? 'd-block':'d-none'" class="font-family-NB" fxLayout fxLayout="row" fxLayoutAlign="end"
                                        fxFlex="100" (page)="pageChanged($event,'family_member')"
                                        [length]="totalRecords" [pageSize]="page.limit"
                                        [pageSizeOptions]="[ 10, 25, 50, 100]">
                                    </mat-paginator> -->
                                </div>
                            </mat-card-content>
                            <mat-card-footer fxLayout fxLayout="row" fxLayoutAlign="end">
                            </mat-card-footer>
                        </mat-card>
                    </div>
                    <!-- / Profile Tab -->
                    <!-- Insurance Tab -->
                    <div fxLayout fxLayout="column" fxFlex="100%" *ngIf="activeLink == 'Insurance'">
                        <mat-card class="example-container" fxLayout fxLayout="column">
                            <mat-card-header class="mb-3" fxLayout fxLayout="row" fxLayoutAlign="space-between">
                                <mat-card-title>
                                    <div class="header-text">Insurance Details</div>
                                </mat-card-title>
                                <button mat-button (click)="addInsuranceDialog()"
                                    class="btn btn-sm btn-yolow text-center font-family-NSB">
                                    <mat-icon class="material-icons-outlined">add</mat-icon> Add Insurance
                                </button>
                            </mat-card-header>
                            <mat-card-content fxLayout fxLayout="row" fxFlex="100%" fxLayoutAlign="start">
                                <div fxLayout fxLayout="column" fxFlexFill class="mat-elevation-z8  font-family-NSB">
                                    <table *ngIf="!isLoading" mat-table [dataSource]="insurance_dataSource" matSort
                                        (matSortChange)="sortInsuranceData()">

                                        <!-- Insurance Type Column -->
                                        <ng-container matColumnDef="insuranceType">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Insurance Type</th>
                                            <td mat-cell *matCellDef="let row">{{ getInsuranceType(row.insuranceType) }}
                                            </td>
                                        </ng-container>

                                        <!-- Insurance Status Column -->
                                        <ng-container matColumnDef="status">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Insurance Status</th>
                                            <td mat-cell *matCellDef="let row" [ngClass]="row.status == 'basic' ? 'text-warning' :
                                            row.status == 'complete' ? 'text-success' : 'text-danger'">
                                                {{row.status}}
                                                <!-- {{row.status == 'basic' ? 'Basic' : row.status == 'complete' ? 'Complete' : 'Pending'}} -->
                                            </td>
                                        </ng-container>

                                        <!-- Insurance Create Column -->
                                        <ng-container matColumnDef="createdDate">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Created</th>
                                            <td mat-cell *matCellDef="let row">{{ getCreate(row.createdDate) }}</td>
                                        </ng-container>

                                        <!-- Action Column -->
                                        <ng-container matColumnDef="action">
                                            <th mat-header-cell *matHeaderCellDef>Action</th>
                                            <td mat-cell *matCellDef="let row">
                                                <button (click)="editInsurance(row)" title="Edit"
                                                    class="btn btn-outline-dark btn-sm mr-2">
                                                    <mat-icon class="btn-edit material-icons-outlined">edit</mat-icon>
                                                </button>
                                                <button (click)="deleteInsuranceDialog(row)" title="Delete"
                                                    class="btn btn-outline-dark btn-sm">
                                                    <mat-icon class="btn-edit material-icons-outlined">delete</mat-icon>
                                                </button>

                                                <!-- <button (click)="editFamilyMember(row)" title="Edit"
                                                        class="btn btn-chat btn-sm col-md-2 mr-2">
                                                        <mat-icon >edit</mat-icon>
                                                    </button> -->
                                            </td>
                                        </ng-container>

                                        <tr class="mat-row" *matNoDataRow>
                                            <td [colSpan]="insurance_displayedColumns.length"
                                                class="mat-cell text-center">No data found</td>
                                        </tr>

                                        <tr class="font-family-NB" mat-header-row
                                            *matHeaderRowDef="insurance_displayedColumns; sticky:true"></tr>
                                        <tr class="font-family-NSB" mat-row
                                            *matRowDef="let row; columns: insurance_displayedColumns"></tr>
                                    </table>
                                    <!-- <mat-paginator [ngClass]="!isLoading ? 'd-block':'d-none'" class="font-family-NB" fxLayout fxLayout="row" fxLayoutAlign="end"
                                        fxFlex="100" (page)="pageChanged($event,'insurance')" [length]="totalRecords"
                                        [pageSize]="page.limit" [pageSizeOptions]="[ 10, 25, 50, 100]">
                                    </mat-paginator> -->
                                </div>
                            </mat-card-content>
                            <mat-card-footer fxLayout fxLayout="row" fxLayoutAlign="space-between">
                            </mat-card-footer>
                        </mat-card>
                    </div>
                    <!-- / Insurance Tab -->
                </div>
            </div>
        </div>
    </div>
</div>