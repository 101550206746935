import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UserDataService } from '../../../services/user-data.service';
import { UserInterface } from '../../../interfaces/user.interface';
import { MatTableDataSource } from '@angular/material/table';
import { ProfileService } from '../../../services/profile.service';
import { ActivatedRoute, Router } from '@angular/router';
import { InsuranceService } from '../../../services/insurance.service';
import moment from 'moment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Sort } from '@angular/material/sort';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-lead-details',
  templateUrl: './lead-details.component.html',
  styleUrls: ['./lead-details.component.scss'],
})
export class LeadDetailsComponent implements OnInit {
  @ViewChild('UploadFile') uploadFile: ElementRef;
  isLoading: boolean = true;
  isFormate: boolean = false;
  userData: UserInterface | undefined;
  leadId: any;
  userProfileSequenceId: any;
  leadDetailsResponse: any = [];
  vehicleDetails: any = [];
  dataSource: MatTableDataSource<any> | any = [];
  displayedColumns: string[] = [
    'sequenceId',
    'endpoint',
    'quoteShellUUID',
    'policyShellUUID',
    'createdDate',
    'response',
    'result',
  ];
  yourVehicleMakeModelTrim: any = [];
  leadData: any = [];
  quotes: any = [];
  insuranceTypes: any[] = [];
  currentInsuranceType: string = '';
  home = './assets/insurance/home.png';
  flood = './assets/insurance/Flood.jpg';
  common = './assets/insurance/LifeInsuranceIcon.png';
  currentInsuranceImage: string = '';

  currentActiveTab: string = 'preview';

  getLeadDetailsPayLoad: any = {
    header: {
      message_uuid: '',
      message_name: 'user_lead-R',
      message_type: 'Q',
      correlation_uuid: '',
      request_id: '',
      version: '1.0',
      service_completion_status_code: '',
      created_by: '',
      created_timestamp: new Date().toLocaleString('en-GB'),
    },
    body: {
      // "cognito_user_id": "9c95864f-f63b-4a14-90a7-bcf5a36e9f13",
      sequence_id: '',
      user_profile_sequence_id: '',
    },
  };
  getLeadExceptionDetailsPayLoad: any = {
    header: {
      message_uuid: '',
      message_name: 'lead_bindable_exception_info',
      message_type: 'Q',
      correlation_uuid: '',
      request_id: '',
      version: '1.0',
      service_completion_status_code: '',
      created_by: '',
      created_timestamp: new Date().toLocaleString('en-GB'),
    },
    body: {
      // "cognito_user_id": "9c95864f-f63b-4a14-90a7-bcf5a36e9f13",
      sequence_id: '',
      user_profile_sequence_id: '',
    },
  };
  uploadDocumentPayLoad: any = {
    header: {
      message_uuid: '',
      message_name: 'upload_policy_document_admin-U',
      message_type: 'Q',
      correlation_uuid: '',
      request_id: '',
      version: '1.0',
      service_completion_status_code: '',
      created_by: '',
      created_timestamp: new Date().toLocaleString('en-GB'),
    },
    body: {
      user_profile_sequence_id: null,
      insurance_documents: [],
      lead_id: null,
      insurance_lead_uuid: null,
      lender_sequence_id: null,
      insurance_type: null,
    },
  };

  getInsuranceTypesPayLoad = {
    header: {
      message_uuid: '',
      message_name: 'insurance_type-R',
      message_type: 'Q',
      correlation_uuid: '',
      request_id: '',
      version: '1.0',
      service_completion_status_code: '',
      created_by: '',
      created_timestamp: new Date().toLocaleString('en-GB'),
    },
    body: {
      // "agent_cognito_id": "",
      start: 0,
      limit: 5,
    },
  };

  constructor(
    private userDataService: UserDataService,
    private profileService: ProfileService,
    private insuranceService: InsuranceService,
    private activatedRoute: ActivatedRoute,
    public snackBar: MatSnackBar,
    private router: Router,
    private datepipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.leadId = this.activatedRoute.snapshot.params['id'];
    this.userProfileSequenceId =
      this.activatedRoute.snapshot.params['profileId'];

    this.userData = this.userDataService.getUserData();
    this.getLeadDetailsPayLoad.header.created_by =
      this.userData != undefined ? this.userData.cognitoId : '';
    this.getInsuranceTypesPayLoad.header.created_by =
      this.userData != undefined ? this.userData.cognitoId : '';
    this.getLeadDetailsPayLoad.body.sequence_id = parseInt(this.leadId);
    this.getLeadDetailsPayLoad.body.user_profile_sequence_id = parseInt(
      this.userProfileSequenceId
    );
    this.getLeadExceptionDetailsPayLoad.header.created_by =
      this.userData != undefined ? this.userData.cognitoId : '';
    this.getLeadExceptionDetailsPayLoad.body.sequence_id = parseInt(
      this.leadId
    );
    this.uploadDocumentPayLoad.header.created_by =
      this.userData != undefined ? this.userData.cognitoId : '';
    this.getLeadDetails();
  }

  async getLeadDetails() {
    this.isLoading = true;
    let parseResponse: any = await this.profileService.getUsersDetails(
      this.getLeadDetailsPayLoad
    );
    let exceptionInfoResponse: any = await this.profileService.getUsersDetails(
      this.getLeadExceptionDetailsPayLoad
    );
    this.dataSource = new MatTableDataSource(
      exceptionInfoResponse?.bindable_exception
    );
    this.isLoading = false;
    this.uploadDocumentPayLoad.body.user_profile_sequence_id =
      parseResponse?.userLeadDetails?.user_profile_sequence_id;
    // const convertTo2DArray = (arr, cols) => {
    //   let result: any = [];
    //   const rows = arr?.length / cols;
    //   for (let i = 0; i < rows; i++) {
    //     result.push(arr.slice(i * cols, i * cols + cols));
    //   }
    //   return result;
    // };
    // const checkQuestionConditional = (
    //   question_condition,
    //   dependentQuestionCardIndx
    // ) => {
    //   const checkCondition = question_condition?.every((val) => {
    //     const findCardIndex =
    //       parseResponse.userLeadDetails.lead_details.cards?.findIndex(
    //         (card) => card?.card_id == val?.card_id
    //       );
    //     const findQuestionIndex =
    //       parseResponse.userLeadDetails.lead_details.cards[
    //         findCardIndex
    //       ]?.card_questions?.find((ques) => {
    //         if (ques?.question_id == val?.previous_question_id) {
    //           return ques?.question_id == val?.previous_question_id;
    //         } else {
    //           return ques?.question_id == val?.question_id;
    //         }
    //       });
    //     const jsonVersion =
    //       parseResponse.userLeadDetails.lead_details?.version?.length > 0
    //         ? parseResponse.userLeadDetails.lead_details?.version
    //         : '1';
    //     if (
    //       jsonVersion === '1' &&
    //       parseResponse.userLeadDetails?.insurance_type?.id === 'home' &&
    //       !(val?.answer_check?.length > 0) &&
    //       findQuestionIndex?.question_id === 84
    //     ) {
    //       const threeYearsAgo = moment().subtract(3, 'years');
    //       if (
    //         findQuestionIndex?.answer &&
    //         moment(findQuestionIndex?.answer).isAfter(threeYearsAgo)
    //       ) {
    //         return true;
    //       }
    //     } else if (
    //       jsonVersion === '1' &&
    //       parseResponse.userLeadDetails?.insurance_type?.id === 'autoandhome' &&
    //       !(val?.answer_check?.length > 0) &&
    //       findQuestionIndex?.question_id === 234
    //     ) {
    //       const threeYearsAgo = moment().subtract(3, 'years');
    //       if (
    //         findQuestionIndex?.answer &&
    //         moment(findQuestionIndex?.answer).isAfter(threeYearsAgo)
    //       ) {
    //         return true;
    //       }
    //     } else if (
    //       ['2', '3'].includes(jsonVersion) &&
    //       parseResponse.userLeadDetails?.insurance_type?.id === 'home' &&
    //       !(val?.answer_check?.length > 0) &&
    //       findQuestionIndex?.question_id === 83
    //     ) {
    //       const dependentAnswers = convertTo2DArray(
    //         findQuestionIndex?.dependent_answers,
    //         findQuestionIndex?.dependent_questions?.length
    //       );
    //       const findDependentQuestion = dependentAnswers?.[0]?.find((ques) => {
    //         return [84, 841].includes(ques?.question_id);
    //       });
    //       const threeYearsAgo = moment().subtract(3, 'years');
    //       if (
    //         findDependentQuestion?.answer &&
    //         moment(findDependentQuestion?.answer).isAfter(threeYearsAgo)
    //       ) {
    //         return true;
    //       }
    //     } else if (
    //       ['2', '3'].includes(jsonVersion) &&
    //       parseResponse.userLeadDetails?.insurance_type?.id === 'autoandhome' &&
    //       !(val?.answer_check?.length > 0) &&
    //       findQuestionIndex?.question_id === 233
    //     ) {
    //       const threeYearsAgo = moment().subtract(3, 'years');
    //       const dependentAnswers = convertTo2DArray(
    //         findQuestionIndex?.dependent_answers,
    //         findQuestionIndex?.dependent_questions?.length
    //       );
    //       const findDependentQuestion = dependentAnswers?.[0]?.find((ques) => {
    //         return [2351, 234].includes(ques?.question_id);
    //       });
    //       if (
    //         findDependentQuestion?.answer &&
    //         moment(findDependentQuestion?.answer).isAfter(threeYearsAgo)
    //       ) {
    //         return true;
    //       }
    //     } else if (
    //       parseResponse.userLeadDetails?.insurance_type?.id === 'auto' &&
    //       !(val?.answer_check?.length > 0) &&
    //       findQuestionIndex?.question_id === 112
    //     ) {
    //       const dependentAnswers = convertTo2DArray(
    //         findQuestionIndex?.dependent_answers,
    //         findQuestionIndex?.dependent_questions?.length
    //       );
    //       const findDependentQuestion = dependentAnswers?.[
    //         dependentQuestionCardIndx
    //       ]?.find((ques) => {
    //         return ques?.question_id == val?.question_id;
    //       });
    //       const yearsAgo = moment().subtract(7, 'years');
    //       if (
    //         findDependentQuestion?.answer &&
    //         moment(findDependentQuestion?.answer).isBefore(yearsAgo)
    //       ) {
    //         return true;
    //       }
    //     } else if (
    //       parseResponse.userLeadDetails?.insurance_type?.id === 'auto' &&
    //       !(val?.answer_check?.length > 0) &&
    //       findQuestionIndex?.question_id === 98
    //     ) {
    //       const yearsAgo = moment().subtract(7, 'years');
    //       if (
    //         findQuestionIndex?.answer &&
    //         moment(findQuestionIndex?.answer).isBefore(yearsAgo)
    //       ) {
    //         return true;
    //       }
    //     } else if (
    //       parseResponse.userLeadDetails?.insurance_type?.id === 'autoandhome' &&
    //       !(val?.answer_check?.length > 0) &&
    //       findQuestionIndex?.question_id === 146
    //     ) {
    //       const dependentAnswers = convertTo2DArray(
    //         findQuestionIndex?.dependent_answers,
    //         findQuestionIndex?.dependent_questions?.length
    //       );
    //       const findDependentQuestion = dependentAnswers?.[
    //         dependentQuestionCardIndx
    //       ]?.find((ques) => {
    //         return ques?.question_id == val?.question_id;
    //       });
    //       const yearsAgo = moment().subtract(7, 'years');
    //       if (
    //         findDependentQuestion?.answer &&
    //         moment(findDependentQuestion?.answer).isBefore(yearsAgo)
    //       ) {
    //         return true;
    //       }
    //     } else if (
    //       parseResponse.userLeadDetails?.insurance_type?.id === 'autoandhome' &&
    //       !(val?.answer_check?.length > 0) &&
    //       findQuestionIndex?.question_id === 131
    //     ) {
    //       const yearsAgo = moment().subtract(7, 'years');
    //       if (
    //         findQuestionIndex?.answer &&
    //         moment(findQuestionIndex?.answer).isBefore(yearsAgo)
    //       ) {
    //         return true;
    //       }
    //     } else {
    //       if (findQuestionIndex?.question_id == val?.previous_question_id) {
    //         const dependentAnswers = convertTo2DArray(
    //           findQuestionIndex?.dependent_answers,
    //           findQuestionIndex?.dependent_questions?.length
    //         );
    //         const findDependentQuestion = dependentAnswers
    //           ?.filter((nestedQue) =>
    //             nestedQue?.find((item) => item?.question_id == val?.question_id)
    //           )
    //           ?.flat()
    //           ?.find(
    //             (item) =>
    //               item?.question_id == val?.question_id &&
    //               val?.answer_check?.includes(item?.answer)
    //           );
    //         if (typeof findDependentQuestion?.answer === 'string') {
    //           return val?.answer_check.includes(findDependentQuestion?.answer);
    //         } else if (Array.isArray(findDependentQuestion?.answer)) {
    //           return val?.answer_check?.some((ans) => {
    //             return ans.split(',')?.every((item) => {
    //               return findDependentQuestion?.answer?.some(({ choice }) =>
    //                 choice?.includes(item)
    //               );
    //             });
    //           });
    //         } else {
    //           return val?.answer_check.includes(findDependentQuestion?.answer);
    //         }
    //       } else {
    //         if (typeof findQuestionIndex?.answer === 'string') {
    //           return val?.answer_check.includes(findQuestionIndex?.answer);
    //         } else if (Array.isArray(findQuestionIndex?.answer)) {
    //           return val?.answer_check?.some((ans) => {
    //             return ans.split(',')?.every((item) => {
    //               return findQuestionIndex?.answer?.some(({ choice }) =>
    //                 choice?.includes(item)
    //               );
    //             });
    //           });
    //         } else {
    //           return val?.answer_check.includes(
    //             findQuestionIndex?.answer?.choice
    //           );
    //         }
    //       }
    //     }
    //   });
    //   return checkCondition;
    // };
    // const replaceLabelConditional = (question_label_replace) => {
    //   const card_id = question_label_replace?.card_id;
    //   const question_id = question_label_replace?.question_id;
    //   const ansIndex = question_label_replace?.answer_index;
    //   const answer = parseResponse.userLeadDetails.lead_details.cards
    //     ?.find((card) => {
    //       return card?.card_id === card_id;
    //     })
    //     ?.card_questions?.find(
    //       (question) => question?.question_id === question_id
    //     )?.answer;
    //   if (answer?.length > 0) {
    //     if (typeof answer === 'string') {
    //       return answer ?? '';
    //     } else {
    //       return answer?.[ansIndex]?.choice ?? '';
    //     }
    //   } else {
    //     return '';
    //   }
    // };
    if (parseResponse?.code === 201) {
      // const condition = parseResponse.userLeadDetails.lead_details.cards?.map(
      //   (card) => {
      //     const checkCondition =
      //       card.card_conditions?.length > 0 &&
      //       card.card_conditions.every((val) => {
      //         const findCardIndex =
      //           parseResponse.userLeadDetails.lead_details.cards?.findIndex(
      //             (condition) => condition?.card_id == val?.card_id
      //           );
      //         const findQuestionIndex =
      //           parseResponse.userLeadDetails.lead_details.cards[
      //             findCardIndex
      //           ]?.card_questions?.find((ques) => {
      //             if (ques?.question_id == val?.previous_question_id) {
      //               return ques?.question_id == val?.previous_question_id;
      //             } else {
      //               return ques?.question_id == val?.question_id;
      //             }
      //           });
      //         if (findQuestionIndex?.question_id == val?.previous_question_id) {
      //           const findDependentQuestion =
      //             findQuestionIndex?.dependent_answers?.find(
      //               (item) =>
      //                 item?.question_id == val?.question_id &&
      //                 val?.answer?.includes(item?.answer)
      //             );
      //           if (typeof findDependentQuestion?.answer === 'string') {
      //             return val?.answer.includes(findDependentQuestion?.answer);
      //           } else if (Array.isArray(findDependentQuestion?.answer)) {
      //             return val?.answer?.some((ans) => {
      //               return ans.split(',')?.every((item) => {
      //                 return findDependentQuestion?.answer?.some(({ choice }) =>
      //                   choice?.includes(item)
      //                 );
      //               });
      //             });
      //           } else {
      //             return val?.answer.includes(
      //               findDependentQuestion?.answer?.choice
      //             );
      //           }
      //         } else {
      //           if (typeof findQuestionIndex?.answer === 'string') {
      //             return val?.answer.includes(findQuestionIndex?.answer);
      //           } else if (Array.isArray(findQuestionIndex?.answer)) {
      //             return val?.answer?.some((ans) => {
      //               return ans.split(',')?.every((item) => {
      //                 return findQuestionIndex?.answer?.some(({ choice }) =>
      //                   choice?.includes(item)
      //                 );
      //               });
      //             });
      //           } else {
      //             return val?.answer.includes(
      //               findQuestionIndex?.answer?.choice
      //             );
      //           }
      //         }
      //       });
      //     const cardQuestions = card?.card_questions.map((question, index) => {
      //       if (
      //         question?.question_condition?.length > 0 &&
      //         !checkQuestionConditional(question?.question_condition, 0)
      //       ) {
      //         return { ...question, isQuestionHide: true };
      //       } else {
      //         if (
      //           [
      //             'conditional_multi_entry_2',
      //             'conditional_multi_entry',
      //             'conditional_checkbox_icon',
      //             'conditional_dropdown',
      //             'dropdown_conditional_multientry',
      //           ].includes(question?.field_type)
      //         ) {
      //           const dependent_answers = question?.dependent_answers?.map(
      //             (item, index) => {
      //               return {
      //                 ...item,
      //                 isQuestionHide:
      //                   item?.question_condition?.length > 0 &&
      //                   !checkQuestionConditional(
      //                     item?.question_condition,
      //                     index
      //                   ),
      //                 question_text: item?.question_text
      //                   ?.trim()
      //                   .replaceAll(
      //                     '{each type}',
      //                     replaceLabelConditional(
      //                       item?.question_label_replace
      //                     ) ?? ''
      //                   )
      //                   .replaceAll(
      //                     '{mention street address}',
      //                     replaceLabelConditional(
      //                       item?.question_label_replace
      //                     ) ?? ''
      //                   ),
      //               };
      //             }
      //           );
      //           const addedMultiEntry =
      //             dependent_answers?.length > 0
      //               ? [
      //                   'conditional_multi_entry',
      //                   'conditional_checkbox_icon',
      //                   'conditional_dropdown',
      //                 ].includes(question?.field_type)
      //                 ? [dependent_answers]
      //                 : convertTo2DArray(
      //                     dependent_answers,
      //                     question?.dependent_questions?.length
      //                   )
      //               : [];

      //           const findQuestion = addedMultiEntry?.map((item) => {
      //             const nestedCheck = item?.filter((dependentAns) =>
      //               dependentAns?.parent_conditional_check?.length > 0
      //                 ? dependentAns.parent_conditional_check.includes(
      //                     question?.answer
      //                   )
      //                 : dependentAns
      //             );
      //             return nestedCheck?.map((nestedQue) => {
      //               if (nestedQue?.dependent_answers?.length > 0) {
      //                 const addedNestedMultiEntry = [
      //                   'conditional_multi_entry',
      //                   'conditional_checkbox_icon',
      //                   'conditional_dropdown',
      //                 ].includes(nestedQue?.field_type)
      //                   ? [nestedQue?.dependent_answers]
      //                   : convertTo2DArray(
      //                       nestedQue?.dependent_answers,
      //                       nestedQue?.dependent_questions?.length
      //                     );

      //                 const nestedConationCheck = addedNestedMultiEntry?.map(
      //                   (item) =>
      //                     item?.filter((dependentAns) =>
      //                       dependentAns?.parent_conditional_check?.length > 0
      //                         ? dependentAns.parent_conditional_check.includes(
      //                             nestedQue?.answer
      //                           )
      //                         : dependentAns
      //                     )
      //                 );
      //                 const nestedQuestionConation = nestedConationCheck?.map(
      //                   (item, index) =>
      //                     item?.map((dependentAns) => {
      //                       return {
      //                         ...dependentAns,
      //                         isQuestionHide:
      //                           dependentAns?.question_condition?.length > 0 &&
      //                           !checkQuestionConditional(
      //                             dependentAns?.question_condition,
      //                             index
      //                           ),
      //                       };
      //                     })
      //                 );
      //                 return {
      //                   ...nestedQue,
      //                   question_text: nestedQue?.question_text
      //                     ?.trim()
      //                     .replaceAll(
      //                       '{each type}',
      //                       replaceLabelConditional(
      //                         nestedQue?.question_label_replace
      //                       ) ?? ''
      //                     )
      //                     .replaceAll(
      //                       '{mention street address}',
      //                       replaceLabelConditional(
      //                         nestedQue?.question_label_replace
      //                       ) ?? ''
      //                     ),
      //                   dependent_answers: nestedQuestionConation,
      //                 };
      //               } else {
      //                 return {
      //                   ...nestedQue,
      //                   question_text: nestedQue?.question_text
      //                     ?.trim()
      //                     .replaceAll(
      //                       '{each type}',
      //                       replaceLabelConditional(
      //                         nestedQue?.question_label_replace
      //                       ) ?? ''
      //                     )
      //                     .replaceAll(
      //                       '{mention street address}',
      //                       replaceLabelConditional(
      //                         nestedQue?.question_label_replace
      //                       ) ?? ''
      //                     ),
      //                 };
      //               }
      //             });
      //           });
      //           const questionConation = findQuestion?.map((item, index) =>
      //             item?.map((dependentAns) => {
      //               return {
      //                 ...dependentAns,
      //                 isQuestionHide:
      //                   dependentAns?.question_condition?.length > 0 &&
      //                   !checkQuestionConditional(
      //                     dependentAns?.question_condition,
      //                     index
      //                   ),
      //               };
      //             })
      //           );
      //           return {
      //             ...question,
      //             question_text: question?.question_text
      //               ?.trim()
      //               .replaceAll(
      //                 '{each type}',
      //                 replaceLabelConditional(
      //                   question?.question_label_replace
      //                 ) ?? ''
      //               )
      //               .replaceAll(
      //                 '{mention street address}',
      //                 replaceLabelConditional(
      //                   question?.question_label_replace
      //                 ) ?? ''
      //               ),
      //             dependent_answers: questionConation,
      //           };
      //         } else {
      //           return {
      //             ...question,
      //             question_text: question?.question_text
      //               ?.trim()
      //               .replaceAll(
      //                 '{each type}',
      //                 replaceLabelConditional(
      //                   question?.question_label_replace
      //                 ) ?? ''
      //               )
      //               .replaceAll(
      //                 '{mention street address}',
      //                 replaceLabelConditional(
      //                   question?.question_label_replace
      //                 ) ?? ''
      //               ),
      //           };
      //         }
      //       }
      //     });
      //     return {
      //       ...card,
      //       card_questions: cardQuestions,
      //       isConditionCard: checkCondition,
      //     };
      //   }
      // );
      // if (
      //   (parseResponse.userLeadDetails?.insurance_type?.id === 'auto' ||
      //     parseResponse.userLeadDetails?.insurance_type?.id ===
      //       'autoandhome') &&
      //   condition?.length > 0
      // ) {
      //   const cardId =
      //     parseResponse.userLeadDetails?.insurance_type?.id === 'auto' ? 8 : 10;
      //   const questionId =
      //     parseResponse.userLeadDetails?.insurance_type?.id === 'auto'
      //       ? 112
      //       : 146;
      //   const dependentQuestionId =
      //     parseResponse.userLeadDetails?.insurance_type?.id === 'auto'
      //       ? ['115', '116', '117']
      //       : ['149', '150', '151'];
      //   const findVehicleCard = condition
      //     ?.find((card) => card?.card_id == cardId)
      //     ?.card_questions?.find((que) => que?.question_id == questionId);
      //   const findVehicleDetails = findVehicleCard?.dependent_answers?.map(
      //     (vehicle) =>
      //       vehicle
      //         ?.filter((question) =>
      //           dependentQuestionId?.includes(question?.question_id?.toString())
      //         )
      //         ?.map((item) => item.answer)
      //         .join(' - ')
      //   );
      //   this.vehicleDetails = findVehicleDetails;
      // }
      // const cardId =
      //   parseResponse.userLeadDetails?.insurance_type?.id === 'auto' ? 7 : 9;
      // const questionID =
      //   parseResponse.userLeadDetails?.insurance_type?.id === 'auto'
      //     ? ['99', '100', '101']
      //     : ['132', '133', '134'];
      // const findByVehicle = condition
      //   ?.find((card) => card?.card_id == cardId)
      //   ?.card_questions?.filter((question) =>
      //     questionID.includes(question?.question_id?.toString())
      //   )
      //   ?.map((que) => que?.answer)
      //   ?.join(' - ');
      // this.yourVehicleMakeModelTrim = findByVehicle;
      console.log(parseResponse.userLeadDetails, "parseResponse.userLeadDetails");
      
      interface CardQuestion {
        question: string;
        answer: string;
      }

      interface Card {
        card_title: string;
        card_questions: CardQuestion[];
      }

      const cards: Card[] = [];

      parseResponse.userLeadDetails.lead_details.cards?.forEach(
        (card: Card) => {
          const findExtensityCardIndex = cards.findIndex(
            (item: Card) => item.card_title === card.card_title
          );
          if (findExtensityCardIndex > -1) {
            const oldQuestion = cards[findExtensityCardIndex].card_questions;
            const newQuestion = card.card_questions;
            const updateCard: Card = {
              ...cards[findExtensityCardIndex],
              card_questions: oldQuestion.concat(newQuestion),
            };
            cards.splice(findExtensityCardIndex, 1, updateCard);
          } else {
            cards.push(card);
          }
        }
      );

      this.leadDetailsResponse = parseResponse.userLeadDetails;
      this.leadData = cards;
      this.quotes = parseResponse.quotes;
      this.getInsuranceTypes();
    } else {
      this.router.navigate(['/customer-leads']);
    }
  }

  async getInsuranceTypes() {
    let parseResponse: any = await this.insuranceService.getInsurances(
      this.getInsuranceTypesPayLoad
    );
    if (parseResponse) {
      this.insuranceTypes = parseResponse.insuranceTypes;
      this.currentInsuranceType =
        this.leadDetailsResponse.insurance_type?.title;
      this.currentInsuranceImage =
        this.leadDetailsResponse.insurance_type?.icon;
      //  this.getInsuranceType(
      //   this.leadDetailsResponse.insurance_type.id
      // );
      // this.insuranceDataService.setInsurancetype(this.insuranceTypes);
    }
  }

  getInsuranceType(key: any) {
    let type = this.insuranceTypes.find((i) => i.id === key);
    if (type) {
      if (type.title == 'Home') {
        this.currentInsuranceImage = this.home;
      } else if (type.title === 'Flood') {
        this.currentInsuranceImage = this.flood;
      } else {
        this.currentInsuranceImage = this.common;
      }
      return type.title;
    } else {
      return '';
    }
  }

  toggleTab(currentTab: string) {
    this.currentActiveTab = currentTab;
  }

  handleClick(insurance_type) {
    this.uploadFile.nativeElement.value = '';
    this.uploadFile.nativeElement.click();
    this.uploadDocumentPayLoad.body.insurance_type = insurance_type;
  }

  async uploadDocument(fileInput: any) {
    this.isFormate = false;
    if (
      Array.from(fileInput.target.files).some(
        (file: any) =>
          !['pdf'].some((format: any) =>
            file.name.toLowerCase().endsWith(format.toLowerCase())
          )
      )
    ) {
      this.isFormate = true;
      return;
    }
    if (fileInput.target.files && fileInput.target.files[0]) {
      let document = fileInput.target.files[0];
      this.isLoading = true;
      const file = await this.convertToBase64(document);
      let uploadPolicyPayload = this.uploadDocumentPayLoad;
      uploadPolicyPayload.body.insurance_documents = [file];
      uploadPolicyPayload.body.lead_id = this.leadDetailsResponse.sequence_id;
      uploadPolicyPayload.body.insurance_lead_uuid = null;
      uploadPolicyPayload.body.lender_sequence_id = null;
      let parseResponse: any = await this.profileService.getUsersDetails(
        uploadPolicyPayload
      );
      this.snackBar.open(parseResponse.message, 'close', {
        duration: 3000,
        verticalPosition: 'top',
        horizontalPosition: 'right',
        panelClass: ['success-snackbar'],
      });
      this.isLoading = false;
    }
  }
  getCreate(created: any) {
    let date = new Date(created);
    return this.datepipe.transform(date, 'MM-dd-yyyy  ::  h:mm a');
  }

  parseJSON(value: any) {
    let data = JSON.parse(value);
    const record = Object.keys(data)
      ?.map((item) => `${item}: ${data[item]}`)
      .join(', ');
    return record;
  }
  convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
}
