import { Buffer } from "buffer";
import * as CryptoJS from "crypto-js";
const crypto = require("crypto-browserify");
const encryptedLocalStorage = (text) => {
  const chatEncryptionSecretKey =
    "asdfghjklqwertyuiopzxcvbnmasdfgh@YOLOH.InSuRAnCE";
  const mdString = crypto
    .createHash("md5")
    .update(
      Buffer.from(chatEncryptionSecretKey).toString("ASCII"),
      0,
      chatEncryptionSecretKey.length
    )
    .digest("hex");
  const substr = mdString.substring(0, 16);

  const CHATKEY = CryptoJS.enc.Utf8.parse(substr);
  const CHATIV = CryptoJS.enc.Utf8.parse(mdString.substring(16));
  var encrypted = CryptoJS.AES.encrypt(text, CHATKEY, {
    keySize: 128 / 8,
    iv: CHATIV,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.toString();
};

//decripting the local storage .
const decryptedLocalStorage = (encryptedText) => {
  const chatEncryptionSecretKey =
    "asdfghjklqwertyuiopzxcvbnmasdfgh@YOLOH.InSuRAnCE";
  const mdString = CryptoJS.MD5(chatEncryptionSecretKey).toString();
  const substr = mdString.substring(0, 16);
  const CHATKEY = CryptoJS.enc.Utf8.parse(substr);
  const CHATIV = CryptoJS.enc.Utf8.parse(mdString.substring(16));
  try {
    const decrypted = CryptoJS.AES.decrypt(encryptedText, CHATKEY, {
      iv: CHATIV,
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC,
    });
    return decrypted.toString(CryptoJS.enc.Utf8) || "";
  } catch (error) {
    console.error("Decryption error:", error);
    return "";
  }
};

export const setLocalStorageRecord = (key, value) => {
  const localStorageItems = localStorage.getItem("data");
  let decryptingRecord = {};
  if (localStorageItems) {
    const decrypting = decryptedLocalStorage(localStorageItems);
    decryptingRecord = JSON.parse(decrypting);
  }
  const data = JSON.stringify({ ...decryptingRecord, [key]: value });
  const encrypting = encryptedLocalStorage(data);
  localStorage.setItem("data", encrypting);
};
const decryptLocalStorageData = () => {
  const value = localStorage.getItem("data");
  if (value) {
    const decrypted = decryptedLocalStorage(value);
    return JSON.parse(decrypted);
  }
  return null;
};
export const getLocalStorageValue = (key) => {
  const data = decryptLocalStorageData();
  return data?.[key] ?? false;
};
