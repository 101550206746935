import {CanActivate, Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {AuthenticationService} from './services/authentication.service';
import { UserDataService } from './services/user-data.service';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {
  constructor(private authenticationService: AuthenticationService, private router: Router, private userDataService: UserDataService,) {
  }

  canActivate(): any {
    let data = this.userDataService.getLocalStorageValue('userDataSimba'); 
    let awsCred =  this.userDataService.getLocalStorageValue('userAwsCred');
    if (data && awsCred) {
      // this.router.navigate(['dashbord']);
      return true;
    } else {
      // window.location.reload();
      this.router.navigate(['/login']);
      return false;
    }
  }
}
