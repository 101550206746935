import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { UserInterface } from '../../../interfaces/user.interface';
import { UserDataService } from '../../../services/user-data.service';
import { CommonService } from '../../../services/common.service';
import { Router } from '@angular/router';
import { AppState } from '../../../services/app.state';
import { environment } from '../../../environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { FilterDialogComponent } from '../../shared/filter-dialog/filter-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ProfileService } from '../../../services/profile.service';
import { InsuranceDataService } from '../../../services/insurance-data.service';
import { InsuranceService } from '../../../services/insurance.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-list-customer-lead',
  templateUrl: './list-customer-lead.component.html',
  styleUrls: ['./list-customer-lead.component.css'],
})
export class ListCustomerLeadComponent implements OnInit {
  count_array: any = [];
  count: any = [];
  userData: UserInterface | undefined;
  displayedColumns: string[] = [
    'customer_name',
    'insurance_type',
    'quotes',
    'status',
    'created_date',
    'action',
  ];

  isLoading: boolean = true;
  dataSource: MatTableDataSource<any> | any = [];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  page: any = { start: 0, limit: 10 };
  totalRecords = 0;
  isFilter: boolean = false;
  sortD: any = { sortField: 'created_date', sortOrder: 'desc' };

  customers: any = [];
  errorMessage: string = '';
  AssetsURL: string = environment.assetsURL;
  firebaseUsersCollection = '';
  getCustomerLeadsPayLoad = {
    header: {
      message_uuid: '',
      message_name: 'customers_lead_list-R',
      message_type: 'Q',
      correlation_uuid: '',
      request_id: '',
      version: '1.0',
      service_completion_status_code: '',
      created_by: '',
      created_timestamp: new Date().toLocaleString('en-GB'),
    },
    body: {
      start: 0,
      limit: 5,
      search: '',
      insurance_type: '',
      sortField: 'created_date', // name, phoneNumber, email
      sortOrder: 'desc', // asc, desc
    },
  };

  getInsuranceTypesPayLoad = {
    header: {
      message_uuid: '',
      message_name: 'insurance_type-R',
      message_type: 'Q',
      correlation_uuid: '',
      request_id: '',
      version: '1.0',
      service_completion_status_code: '',
      created_by: '',
      created_timestamp: new Date().toLocaleString('en-GB'),
    },
    body: {
      // "agent_cognito_id": "",
      start: 0,
      limit: 5,
    },
  };

  insuranceTypes: any[] = [];

  constructor(
    private afs: AngularFirestore,
    private router: Router,
    private commanService: CommonService,
    private profileService: ProfileService,
    private userDataService: UserDataService,
    private insuranceService: InsuranceService,
    private datepipe: DatePipe,
    private insuranceDataService: InsuranceDataService,
    public dialog: MatDialog,
    private appState: AppState
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.firebaseUsersCollection = environment.firebaseUsersCollection;
    this.userData = this.userDataService.getUserData();
    this.getCustomerLeadsPayLoad.header.created_by =
      this.userData != undefined ? this.userData.cognitoId : '';
    this.getCustomerLeadsPayLoad.body.start = this.page.start;
    this.getCustomerLeadsPayLoad.body.limit = this.page.limit;

    this.getInsuranceTypesPayLoad.header.created_by =
      this.userData != undefined ? this.userData.cognitoId : '';
    this.getAllUserList();

    this.getInsuranceTypes();
    // this.insuranceDataService.getInsuranceType.subscribe((result: any) => {
    //   this.insuranceTypes = result;
    //   if (this.insuranceTypes == null) {
    //      this.getInsuranceTypes();
    //    }
    // });
  }

  getCreate(created: any) {
    let date = new Date(created);
    return this.datepipe.transform(date, 'MM-dd-yyyy  ::  h:mm a');
  }

  async getInsuranceTypes() {
    this.isLoading = true;
    let parseResponse: any = await this.insuranceService.getInsurances(
      this.getInsuranceTypesPayLoad
    );
    if (parseResponse) {
      this.isLoading = false;
      this.insuranceTypes = parseResponse.insuranceTypes;
      // this.insuranceDataService.setInsurancetype(this.insuranceTypes);
    }
  }

  getInsuranceType(key: any) {
    let type = this.insuranceTypes.find((i) => i.id === key);
    if (type) {
      return type.title;
    } else {
      return '';
    }
  }

  // ngAfterViewInit() {
  // this.dataSource.paginator = this.paginator;
  // this.dataSource.sort = this.sort;
  // }
  async getAllUserList() {
    this.isLoading = true;
    let parseResponse: any = await this.profileService.getUsersDetails(
      this.getCustomerLeadsPayLoad
    );
    if (parseResponse) {
      this.isLoading = false;
      this.customers = parseResponse.leads;
      this.totalRecords = parseResponse.total;
      this.dataSource = new MatTableDataSource(this.customers);
    }
  }
  sortData(sort: Sort) {
    this.sortD.sortField = sort.active;
    this.sortD.sortOrder = sort.direction;
    // this.sort.sortChange.emit(this.sortD);

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.getCustomerLeadsPayLoad.body.start = this.page.start;
    this.getCustomerLeadsPayLoad.body.limit = this.page.limit;

    this.getCustomerLeadsPayLoad.body.sortField = this.sortD.sortField;
    this.getCustomerLeadsPayLoad.body.sortOrder = this.sortD.sortOrder;
    this.getAllUserList();
  }
  pageChanged(event: any) {
    this.page.limit = event.pageSize;
    this.page.start = event.pageIndex * event.pageSize;
    this.getCustomerLeadsPayLoad.body.start = this.page.start;
    this.getCustomerLeadsPayLoad.body.limit = this.page.limit;
    this.getAllUserList();
  }
  clearFilter = () => {
    this.isFilter = false;
    // this.getUsersDetailsPayLoad.body.start = 0;
    // this.getUsersDetailsPayLoad.body.limit = 10;
    this.getCustomerLeadsPayLoad.body.search = '';
    this.getCustomerLeadsPayLoad.body.insurance_type = '';
    this.getAllUserList();
  };
  filterDialog = () => {
    const dialogRef = this.dialog.open(FilterDialogComponent, {
      width: '500px',
      data: {
        header: 'Search Customer Leads',
        allInsuranceType: this.insuranceTypes,
        insurance_type: this.getCustomerLeadsPayLoad.body.insurance_type,
        search: this.getCustomerLeadsPayLoad.body.search,
        type: 'customer_leads',
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.action == 'Search') {
        // this.getCustomerLeadsPayLoad.body.start = 0;
        // this.getCustomerLeadsPayLoad.body.limit = 10;
        // this.page.start = 0;
        // this.page.limit = 10;
        this.isFilter = true;
        this.getCustomerLeadsPayLoad.body.insurance_type = '';
        this.getCustomerLeadsPayLoad.body.search = '';
        let search = result.filterLeads.search.trim();
        let insurance_type = result.filterLeads.insurance_type.trim();
        if (search != '') this.getCustomerLeadsPayLoad.body.search = search;
        if (insurance_type != '')
          this.getCustomerLeadsPayLoad.body.insurance_type = insurance_type;

        this.paginator.firstPage();
        this.getAllUserList();
      }
    });
  };
  showCustomerDetails(row: any) {
    this.router.navigate([
      '/leads-detail',
      row.sequence_id,
      row.user_profile_sequence_id,
    ]);
  }
}
