import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { Buffer } from "buffer";
// import { String, StringBuilder } from 'typescript-string-operations';
import { allURLS } from './allURL';

const crypto = require('crypto-browserify')


@Injectable({
    providedIn: 'root'
})
export class CryptoService {

    encryptSecretKey = allURLS['ENCRYPTSECRETKEY'];
    chatEncryptionSecretKey = allURLS['CHATENCRYPTIONKEY'];
    // ivParameterSpec: StaticRange;
    KEY: any = [];
    IV: any = [];
    CHATKEY: any = [];
    CHATIV: any = [];
    // encodedUuid: string = null;
    // encryptedString: string = null;
    // mdString: string = null;
    encodedUuid: string = '';
    encryptedString: string = '';
    mdString: string = '';

    constructor() {
    }

    //encrypting the payload body.
    getEncryptedBody(cognitoId: string, plainText: string) {
        this.createKeys(cognitoId);
        this.encryptedString = this.encrypt(plainText)
        return this.encryptedString;
    }

    //decripting the payload body.
    getDecryptedBody(cognitoId: string, encryptedText: string) {
        this.createKeys(cognitoId)
        return this.decrypt(encryptedText)
    }

    //converting congnitoId in base64 format.
    getBase64String(plainText: string) {
        return Buffer.from(plainText.toString()).toString('base64');
    }

    //create MD5 hash using congitoId and encryptSecretKey.
    createKeys(cognitoId: String) {
        var uuidSimple = cognitoId.toString().replace(/-/g, '');
        this.mdString = crypto.createHash('md5').update(Buffer.from((uuidSimple + this.encryptSecretKey))
            .toString("ASCII"), 0, (uuidSimple.length + this.encryptSecretKey.length)).digest('hex');
        this.KEY = CryptoJS.enc.Utf8.parse(this.mdString!!.substring(0, 16));
        this.IV = CryptoJS.enc.Utf8.parse(this.mdString!!.substring(16));
        this.encodedUuid = Buffer.from(cognitoId.toString()).toString('base64');

    }

    //converting text to Encryption.
    encrypt(textToEncrypt: string) {
        var encrypted = CryptoJS.AES.encrypt(textToEncrypt,
            this.KEY,
            {
                keySize: 128 / 8,
                iv: this.IV,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            });
        return encrypted.toString();
    }

    //converting text to Decryption.
    decrypt(textToDecrypt: string) {

        var decrypted = CryptoJS.AES.decrypt(textToDecrypt, this.KEY, {
            keySize: 128 / 8,
            iv: this.IV,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });

        return decrypted.toString(CryptoJS.enc.Utf8);
    }


    //decripting the payload body.
    getEncryptedChat(text: string) {
        this.createChatKeys();
        return this.encryptChat(text);
    }

    //decripting the payload body.
    getDecryptedChat(encryptedText: string) {
        this.createChatKeys();
        return this.decryptChat(encryptedText)
    }

    createChatKeys() {
        let mdString = crypto.createHash('md5').update(Buffer.from((this.chatEncryptionSecretKey))
            .toString("ASCII"), 0, (this.chatEncryptionSecretKey.length)).digest('hex');
        this.CHATKEY = CryptoJS.enc.Utf8.parse(mdString!!.substring(0, 16));
        this.CHATIV = CryptoJS.enc.Utf8.parse(mdString!!.substring(16));
    }

    //converting text to Decryption.
    decryptChat(textToDecrypt: string) {
        var decrypted = CryptoJS.AES.decrypt(textToDecrypt, this.CHATKEY, {
            keySize: 128 / 8,
            iv: this.CHATIV,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        return decrypted.toString(CryptoJS.enc.Utf8);
    }

    //converting text to Decryption.
    encryptChat(text: string) {

        var encrypted = CryptoJS.AES.encrypt(text,
            this.CHATKEY,
            {
                keySize: 128 / 8,
                iv: this.CHATIV,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            });
        return encrypted.toString();
    }
    //decripting the local storage.
  encryptedLocalStorage(text: string) {
    const chatEncryptionSecretKey =
      'asdfghjklqwertyuiopzxcvbnmasdfgh@YOLOH.InSuRAnCE';
    const mdString = crypto
      .createHash('md5')
      .update(
        Buffer.from(chatEncryptionSecretKey).toString('ASCII'),
        0,
        chatEncryptionSecretKey.length
      )
      .digest('hex');
    const substr = mdString.substring(0, 16);

    const CHATKEY = CryptoJS.enc.Utf8.parse(substr);
    const CHATIV = CryptoJS.enc.Utf8.parse(mdString.substring(16));
    var encrypted = CryptoJS.AES.encrypt(text, CHATKEY, {
      keySize: 128 / 8,
      iv: CHATIV,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    return encrypted.toString();
  }

  //decripting the local storage .
  decryptedLocalStorage(encryptedText: string) {
    const chatEncryptionSecretKey =
      'asdfghjklqwertyuiopzxcvbnmasdfgh@YOLOH.InSuRAnCE';
    const mdString = CryptoJS.MD5(chatEncryptionSecretKey).toString();
    const substr = mdString.substring(0, 16);
    const CHATKEY = CryptoJS.enc.Utf8.parse(substr);
    const CHATIV = CryptoJS.enc.Utf8.parse(mdString.substring(16));
    try {
      const decrypted = CryptoJS.AES.decrypt(encryptedText, CHATKEY, {
        iv: CHATIV,
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC,
      });
      return decrypted.toString(CryptoJS.enc.Utf8) || '';
    } catch (error) {
      console.error('Decryption error:', error);
      return '';
    }
  }
}