import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UserDataService } from '../services/user-data.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  // encapsulation: ViewEncapsulation.None,
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {

  @Input() isOpen: boolean = false;

  constructor(private userDataService: UserDataService,) {
    
  }
  onToggle(flag: any) {
    this.isOpen = flag;
  }
  ngOnInit(): void {
    let userLogData: any = this.userDataService.getLocalStorageValue('userDataSimba') ? this.userDataService.getLocalStorageValue('userDataSimba') : '';
    let parsedData = JSON.parse(userLogData);

    var arr = [{
      "token": "f8Yrm7lZSsWXKlnPdBQybb:APA91bGpaYYf9ifdPAh6XaW-jpP_HJ_WfQ4aL3tVwKkHv1svfiJsLwjTT8ybgV7A-u11H81fyrJW5sx9NaEjGIRN3x_oIyOk2G94fs6W0OC7GJNxOl-Rc9HjGpZo3jMv8n2Gz2LagGpx",
      "current_support_id": parsedData.cognitoId,
      "user_name": "Yoloh Support",
      "id": 69,
      "status": "assigned",
      "last_message": "7WJ3ytwySHftgTmOsjKTFA==",
      // "device_type": "android",
      "support_name": parsedData.email,
      // "last_message_timestamp": 1654257646095
    }];
    this.userDataService.setLocalStorageRecord('currentUser', JSON.stringify(arr));
  }

}
