import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { UserInterface } from './../../../interfaces/user.interface';
import { AuthenticationService } from './../../../services/authentication.service';
import { UserDataService } from './../../../services/user-data.service';
import Menu from '../../../../assets/data/Menu.json';
import { environment } from '../../../environments/environment';
import { MatMenuTrigger } from '@angular/material/menu';
import { ProfileService } from './../../../services/profile.service';
import { CryptoService } from './../../../services/crypto.service';
import { InsuranceService } from './../../../services/insurance.service';
import { Router } from '@angular/router';
import { AppState } from './../../../services/app.state';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { InsuranceDataService } from '../../../services/insurance-data.service';
import { CommonService } from '../../../services/common.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  isLoggedIn: boolean;
  menuItems: any[];
  AssetsURL: string = environment.assetsURL;
  InitalCharacterURL: string = environment.initialCharacterURL;
  userData: UserInterface;
  notificationCount: number = 0;
  notifications: any[] = [];
  profile_image: string = '';
  unreadMsgCount: any = 0;
  Menu: { path: string; title: string; icon: string; class: string }[] =
    Menu.ROUTES;

  @ViewChild(MatMenuTrigger) ddTrigger: MatMenuTrigger | any;
  @Input() isOpen: boolean = false;
  @Output() onToggle = new EventEmitter();

  toggle() {
    if (this.isOpen === true) {
      this.isOpen = false;
    } else {
      this.isOpen = true;
    }
    this.onToggle.emit(this.isOpen);
  }

  loadNotificationPayload = {
    header: {
      message_uuid: '',
      message_name: 'admin_notifications-R',
      message_type: 'Q',
      correlation_uuid: '',
      request_id: '',
      version: '1.0',
      service_completion_status_code: '',
      created_by: '',
      created_timestamp: new Date().toLocaleString('en-GB'),
    },
    body: {
      limit: 10,
      start: 0,
    },
  };
  notificationRead = {
    header: {
      message_uuid: '',
      message_name: 'admin_notification-U',
      message_type: 'Q',
      correlation_uuid: '',
      request_id: '',
      version: '1.0',
      service_completion_status_code: '',
      created_by: '',
      created_timestamp: new Date().toLocaleString('en-GB'),
    },
    body: {},
  } as any;

  notificationAllRead = {
    header: {
      message_uuid: '',
      message_name: 'admin_notification-CL',
      message_type: 'Q',
      correlation_uuid: '',
      request_id: '',
      version: '1.0',
      service_completion_status_code: '',
      created_by: '',
      created_timestamp: new Date().toLocaleString('en-GB'),
    },
    body: { is_read: '' },
  } as any;

  getInsuranceTypesPayLoad = {
    header: {
      message_uuid: '',
      message_name: 'insurance_type-R',
      message_type: 'Q',
      correlation_uuid: '',
      request_id: '',
      version: '1.0',
      service_completion_status_code: '',
      created_by: '',
      created_timestamp: new Date().toLocaleString('en-GB'),
    },
    body: {
      start: 0,
      limit: 5,
    },
  };
  insuranceTypes: any[] = [];

  readInsurancePayLoad = {
    header: {
      message_uuid: '',
      message_name: 'admin_notifications-U',
      message_type: 'Q',
      correlation_uuid: '',
      request_id: '',
      version: '1.0',
      service_completion_status_code: '',
      created_by: '',
      created_timestamp: new Date().toLocaleString('en-GB'),
    },
    body: {
      // user_id: '',
      sequence_id: '',
    },
  };

  constructor(
    private insuranceService: InsuranceService,
    private authenticationService: AuthenticationService,
    private userDataService: UserDataService,
    private profileService: ProfileService,
    private cryptoService: CryptoService,
    private insuranceDataService: InsuranceDataService,
    private commanService: CommonService,
    private afs: AngularFirestore,
    private router: Router,
    private appState: AppState
  ) {
    // this.Menu=Menu.ROUTES
    this.isLoggedIn = false;
    this.menuItems = this.Menu; //Menu.ROUTES;
    this.userData = this.userDataService.getUserData();
    if (this.userData) {
      this.isLoggedIn = true;
      this.profile_image =
        this.userData != null && this.userData != undefined
          ? // "/assets/img/" + 'User_Profile_Icon' + this.userData.email.toString().charAt(0).toLowerCase() + '.png' :
            './assets/img/User_Profile_Icon.png'
          : './assets/img/User_Profile_Icon.png';
    }
    this.getInsuranceTypesPayLoad.header.created_by = this.userData.cognitoId;
    this.loadNotificationPayload.header.created_by = this.userData.cognitoId;
    this.readInsurancePayLoad.header.created_by = this.userData.cognitoId;
    // this.readInsurancePayLoad.body.user_id = this.userData.cognitoId;

    this.loadCount();
    this.getInsuranceTypes();
    this.loadNotifications();
    this.fetchNotificationsJob();
  }

  onChatClick() {
    // this.router.navigate([]).then(result => { window.open(`${window.location.origin}/chat`, '_blank'); }).then(() => {
    //   window.location.reload();
    // });
  }

  public signOut() {
    this.authenticationService.logout();
  }

  loadCount() {
    this.unreadMsgCount = 0;
    this.afs
      .collection('users', (ref: any) =>
        ref.where('current_support_id', '==', this.userData.cognitoId)
      )
      .snapshotChanges()
      .subscribe((snapshot: any) => {
        let user = [];
        user = snapshot.map((a: any) => {
          return a;
        });

        if (user.length > 0) {
          this.unreadMsgCount = 0;
          user.map(async (a: any) => {
            await a.payload.doc.ref
              .collection('support_chat')
              .orderBy('timestamp', 'asc')
              .onSnapshot(async (as: any) => {
                if (as.docs && as.docs.length > 0) {
                  let flag = 0;
                  as.docs.filter(async (d: any, i: any) => {
                    let data = d.data();
                    if (data.from == 'user' && data.support_read == false) {
                      await flag++;
                      // await this.unreadMsgCount++;
                    }
                    if (i == as.docs.length - 1) {
                      this.unreadMsgCount =
                        parseInt(this.unreadMsgCount) + flag;
                    }
                  });
                }
              });
          });
        }
      });
  }

  async loadNotifications() {
    let response: any = await this.profileService.getUsersDetails(
      this.loadNotificationPayload
    );
    if (response) {
      if (
        response &&
        response.userInsurances &&
        response.userInsurances.length > 0
      ) {
        this.notificationCount = response.notification_count;
        this.notifications = response.userInsurances;
      } else {
        this.notifications = [];
      }
    }
  }

  async ViewMoreNotification() {
    this.loadNotificationPayload.body.start =
      this.loadNotificationPayload.body.start + 10;
    await this.loadNotifications();
    this.onOpenMenu();
  }

  async getInsuranceTypes() {
    this.insuranceDataService.getInsuranceType.subscribe((result: any) => {
      this.insuranceTypes = result;
    });
    if (this.insuranceTypes == null) {
      let parseResponse: any = await this.insuranceService.getInsurances(
        this.getInsuranceTypesPayLoad
      );
      if (parseResponse) {
        this.insuranceTypes = parseResponse.insuranceTypes;
        this.insuranceDataService.setInsurancetype(this.insuranceTypes);
      }
    }
  }

  getNotificationText(notif: any) {
    return notif.notification_description ?? '';
  }

  onOpenMenu() {
    this.ddTrigger.openMenu();
  }

  notificationClickRedirect(type: string) {
    let insuranceRedirect = [
      'insurance_policy_pending_admin',
      'insurance_policy_basic',
      'insurance_policy_completed',
      'insurance_policy_ocr_admin',
    ];

    let voiData = ['voi_prepared', 'voi_prepared_lender', 'voi_created_admin'];
    let agentData = [
      'agent_profile_created',
      'agent_profile_created_admin',
      'agent_profile_approved',
      'agent_profile_rejected',
      'agent_license_add',
      'agent_license_add_admin',
      'agent_license_add_admin',
      'agent_license_rejected',
    ];
    if (insuranceRedirect.includes(type)) {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/insurances']);
      });
    }
    if (voiData.includes(type)) {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/ivs']);
      });
    }
    if (agentData.includes(type)) {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/agents']);
      });
    }
    this.loadNotifications();
  }

  async clickNotification(notif: any) {
    let notificationType = notif.notification_type;
    let createdId = this.userDataService.getLocalStorageValue('currentUser')|| '';
    let data = JSON.parse(createdId);
    let currentSupportId = data[0].current_support_id || '';
    this.notificationRead.header.created_by = currentSupportId;
    this.notificationRead.body.sequence_id = notif.notification_id;
    await this.profileService
      .getUserNotifications(this.notificationRead)
      .then(() => {
        this.notificationClickRedirect(notificationType);
      })
      .catch(() => {});
  }

  async readAllNotification() {
    let createdId = this.userDataService.getLocalStorageValue('currentUser') || '';
    let data = JSON.parse(createdId);
    let currentSupportId = data[0].current_support_id || '';
    this.notificationAllRead.header.created_by = currentSupportId;
    this.notificationAllRead.body.is_read = true;
    await this.profileService
      .getUsersDetails(this.notificationAllRead)
      .then((res) => {
        this.loadNotifications();
      })
      .catch(() => {});
  }

  getIconUrl(notif: any): string {
    if (notif?.icon_url) {
      return notif.icon_url;
    }
    return '';
  }

  getNotificationTitle(notif: any) {
    return notif?.notification_title || '';
  }

  fetchNotificationsJob() {
    // setInterval(() => {
    //   // this.loadNotifications();
    // }, 10000);
  }
}
