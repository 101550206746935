import { Component, OnInit, ViewChild } from '@angular/core';
import { UserInterface } from './../../../interfaces/user.interface';
import { UserDataService } from './../../../services/user-data.service';
import { InsuranceDataService } from '../../../services/insurance-data.service';
import { CommonService } from '../../../services/common.service';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AddInsuranceComponent } from '../../insurance/add-insurance/add-insurance.component';
import { DeleteDialogComponent } from '../../shared/delete-dialog/delete-dialog.component';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { AppState } from '../../../services/app.state';
import { DatePipe } from '@angular/common';
import { InsuranceService } from '../../../services/insurance.service';
import { ProfileService } from '../../../services/profile.service';

@Component({
  selector: 'app-details-customer',
  templateUrl: './details-customer.component.html',
  styleUrls: ['./details-customer.component.scss'],
})
export class DetailsCustomerComponent implements OnInit {
  links = ['Profile', 'Insurance'];
  isLoading: boolean = true;
  // isLoading: boolean = true;
  activeLink: string = this.links[0];

  userData: UserInterface | undefined;

  family_dataSource: MatTableDataSource<any> | any = [];
  family_displayedColumns: string[] = [
    // 'profilePic',
    'name',
    'dob',
    'relationship',
  ];

  insurance_dataSource: MatTableDataSource<any> | any = [];
  insurance_displayedColumns: string[] = [
    'insuranceType',
    'status',
    'createdDate',
    'action',
  ];
  insuranceTypes: any = [];

  @ViewChild(MatPaginator) paginator: MatPaginator | any = [];
  @ViewChild(MatSort) sort: MatSort | any = [];
  page: any = { start: 0, limit: 10 };
  totalRecords = 0;

  profileDetails: any = null;
  address: any = [];
  AssetsURL: string = environment.assetsURL;

  getFamilyDetailsPayload = {
    header: {
      message_uuid: '',
      message_type: 'Q',
      version: '1.0',
      message_name: 'family_details-R',
      correlation_uuid: '',
      service_completion_status_code: '',
      created_by: '',
      created_timestamp: new Date().toLocaleString('en-GB'),
    },
    body: {
      sequence_id: 0,
    },
  };
  getInsuranceDetailsPayload = {
    header: {
      message_uuid: '',
      message_type: 'Q',
      version: '1.0',
      message_name: 'user_insurances-R',
      correlation_uuid: '',
      service_completion_status_code: '',
      created_by: '',
      created_timestamp: new Date().toLocaleString('en-GB'),
    },
    body: {
      user_id: '',
    },
  };
  getInsuranceTypesPayLoad = {
    header: {
      message_uuid: '',
      message_name: 'insurance_type-R',
      message_type: 'Q',
      correlation_uuid: '',
      request_id: '',
      version: '1.0',
      service_completion_status_code: '',
      created_by: '',
      created_timestamp: new Date().toLocaleString('en-GB'),
    },
    body: {
      // "agent_cognito_id": "",
      start: 0,
      limit: 5,
    },
  };
  getDeleteInsurancePayLoad = {
    header: {
      message_uuid: '',
      message_type: 'Q',
      version: '1.0',
      message_name: 'admin_user_insurance_policy-D',
      correlation_uuid: '',
      service_completion_status_code: '',
      created_by: '',
      created_timestamp: new Date().toLocaleString('en-GB'),
    },
    body: {
      cognito_user_id: '',
      insurance_id: '',
    },
  };

  cognitoId: any;
  constructor(
    private appState: AppState,
    public snackBar: MatSnackBar,
    private dialog: MatDialog,
    private userDataService: UserDataService,
    private insuranceService: InsuranceService,
    private profileService: ProfileService,
    private router: Router,
    private insuranceDataService: InsuranceDataService,
    private datepipe: DatePipe,
    private commanService: CommonService
  ) {}

  ngOnInit(): void {
    let profileDetails = this.appState.get('CUSTOMER');
    if (profileDetails == null) {
      this.router.navigate(['/customers']);
    } else {
      this.profileDetails = profileDetails;
      this.cognitoId = this.profileDetails.cognitoId;

      this.userData = this.userDataService.getUserData();

      this.getFamilyDetailsPayload.header.created_by =
        this.userData != undefined ? this.userData.cognitoId : '';
      this.getFamilyDetailsPayload.body.sequence_id = Number(
        this.profileDetails.id
      );

      this.getFamilyDetails();
    }
  }

  async getInsuranceTypes() {
    this.isLoading = true;
    let parseResponse: any = await this.insuranceService.getInsurances(
      this.getInsuranceTypesPayLoad
    );

    if (parseResponse) {
      this.isLoading = false;
      this.insuranceTypes = parseResponse.insuranceTypes;
      this.insuranceDataService.setInsurancetype(this.insuranceTypes);
    }
  }
  async getFamilyDetails() {
    this.isLoading = true;

    let parseResponse: any = await this.profileService.getUsersDetails(
      this.getFamilyDetailsPayload
    );

    this.isLoading = false;
    if (parseResponse && parseResponse.code == 200) {
      this.family_dataSource = new MatTableDataSource(
        parseResponse.familyMembers
      );
      this.totalRecords = parseResponse.familyMembers.length;
    }
  }
  async getInsuranceDetails() {
    this.isLoading = true;
    let parseResponse: any = await this.profileService.getUsersDetails(
      this.getInsuranceDetailsPayload
    );
    if (parseResponse) {
      this.isLoading = false;
      this.insurance_dataSource = new MatTableDataSource(
        parseResponse.userInsurances
      );
      this.totalRecords = parseResponse.userInsurances.length;
    }
  }
  activeTab(link: any) {
    this.activeLink = link;
    if (this.activeLink == 'Profile' && this.family_dataSource.length == 0) {
      this.getFamilyDetails();
    } else if (
      this.activeLink == 'Insurance' &&
      this.insurance_dataSource.length == 0
    ) {
      this.insuranceInit();
      this.getInsuranceDetails();
    }
  }
  insuranceInit() {
    this.getInsuranceDetailsPayload.header.created_by =
      this.userData != undefined ? this.userData.cognitoId : '';
    this.getInsuranceDetailsPayload.body.user_id =
      this.profileDetails.cognitoId != undefined
        ? this.profileDetails.cognitoId
        : '';

    this.getInsuranceTypesPayLoad.header.created_by =
      this.userData != undefined ? this.userData.cognitoId : '';
    // this.getInsuranceTypesPayLoad.body.agent_cognito_id = this.userData != undefined ? this.userData.cognitoId : '';

    // check insuranceType having data or not
    this.insuranceDataService.getInsuranceType.subscribe((result: any) => {
      this.insuranceTypes = result;
    });

    if (this.insuranceTypes == null) this.getInsuranceTypes();
  }

  getDob(created: any) {
    let date = new Date(created);
    return this.datepipe.transform(date, 'yyyy-MM-dd');
  }

  getCreate(created: any) {
    let date = new Date(created);
    return this.datepipe.transform(date, 'yyyy-MM-dd h:mm:ss a');
  }

  getInsuranceType(key: any) {
    let type = this.insuranceTypes.find((i: any) => i.id === key);
    if (type) {
      return type.title;
    } else {
      return '';
    }
  }

  getInsuranceStatus(verified: any) {
    if (verified) {
      return 'Verified';
    } else {
      return 'Pending';
    }
  }

  sortInsuranceData() {
    this.insurance_dataSource.paginator = this.paginator;
    this.insurance_dataSource.sort = this.sort;
  }
  sortFamilyData() {
    this.family_dataSource.paginator = this.paginator;
    this.family_dataSource.sort = this.sort;
  }
  async deleteInsurance() {
    this.isLoading = true;
    let parseResponse: any = await this.insuranceService.getInsurances(
      this.getDeleteInsurancePayLoad
    );
    if (parseResponse) {
      this.isLoading = false;
      this.snackBar.open(parseResponse.message, 'close', {
        duration: 3000,
        verticalPosition: 'top',
        horizontalPosition: 'right',
        panelClass: ['success-snackbar'],
      });
      this.getInsuranceDetails();
    }
  }
  deleteInsuranceDialog(row: any) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '500px',
      data: {
        header: 'Delete',
        description: `Are you sure want to delete ${row.insuranceType} ?`,
        id: row.insuranceSequenceId,
        action: 'DELETE',
        okbutton: 'DELETE',
        cancelbutton: 'CANCEL',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.event == 'DELETE') {
        this.getDeleteInsurancePayLoad.header.created_by =
          this.userData != undefined ? this.userData.cognitoId : '';
        this.getDeleteInsurancePayLoad.body.cognito_user_id =
          this.profileDetails.cognitoId != undefined
            ? this.profileDetails.cognitoId
            : '';
        this.getDeleteInsurancePayLoad.body.insurance_id =
          row.insuranceSequenceId;
        this.deleteInsurance();
      }
    });
  }
  addInsuranceDialog(): void {
    const dialogRef = this.dialog.open(AddInsuranceComponent, {
      width: '500px',
      data: { header: 'Add Insurance', type: 'add' },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.action == 'add') {
        this.getInsuranceDetails();
      }
    });
  }
  editInsurance(i: any) {
    this.appState.set('INSURANCE', i);
    this.appState.set('ROUTE', '/details-customer');
    this.router.navigate(['/update-insurances']);
  }
  pageChanged(event: any, type: any) {
    if (type == 'family_member') {
      this.page.limit = event.pageSize;
      this.page.start = event.pageIndex * event.pageSize;
    } else if (type == 'insurance') {
      this.page.limit = event.pageSize;
      this.page.start = event.pageIndex * event.pageSize;
    }
  }
}
