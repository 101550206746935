import React, { createContext, useContext, useReducer, useState } from "react";
import { AuthContext } from "./AuthContext";
import { getLocalStorageValue, setLocalStorageRecord } from "../local-storage-service";

export const ChatContext = createContext<any>(null);

export const ChatContextProvider = ({ children }: any) => {
  setLocalStorageRecord("selectedUser", "");
  const currentUser = JSON.parse(getLocalStorageValue("currentUser") || "")[0];
  const INITIAL_STATE: any = {
    userid: "null",
    user: {},
  };

  const chatReducer = (state: any, action: any): any => {
    switch (action.type) {
      case "CHANGE_USER":
        return {
          user: action?.payload,
          id:  action?.payload?.id,
          adminuser: currentUser,
        };

      default:
        return state;
    }
  };

  const [state, dispatch] = React.useReducer(chatReducer, INITIAL_STATE);
  return (
    <ChatContext.Provider value={{ data: state, dispatch }}>
      {children}
    </ChatContext.Provider>
  );
};
