import { User } from '../models/user.model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Subscription } from 'rxjs';
import { CryptoService } from './crypto.service';

@Injectable({
  providedIn: 'root',
})
export class UserDataService {
  user: any;
  constructor(private cryptoService: CryptoService) {}
  setType(type: string) {
    this.setLocalStorageRecord('type', type);
  }

  getType() {
    return this.getLocalStorageValue('type');
  }

  removeType() {
    this.setLocalStorageRecord('type', '');
  }

  getUserData() {
    this.user = this.getLocalStorageValue('userDataSimba');
    return JSON.parse(this.user);
  }

  setUserData(user: any) {
    this.setLocalStorageRecord('userDataSimba', JSON.stringify(user));
  }

  removeUserData() {
    this.setLocalStorageRecord('userDataSimba', '');
  }

  getAwsCred() {
    const data = this.getLocalStorageValue('userAwsCred');
    return data ? JSON.parse(data) : null;
  }

  setAwsCred(cred: any) {
    this.setLocalStorageRecord('userAwsCred', JSON.stringify(cred));
  }

  getUserProfileData() {
    this.user = this.getLocalStorageValue('userProfileData');
    return JSON.parse(this.user);
  }

  setUserProfileData(user: any) {
    this.setLocalStorageRecord('userProfileData', JSON.stringify(user));
  }

  removeUserProfileData() {
    this.setLocalStorageRecord('userProfileData', '');
  }
  clearLocalStorageRecord() {
    localStorage.clear();
  }

  setLocalStorageRecord = (key, value) => {
    const localStorageItems = localStorage.getItem('data');
    let decryptingRecord = {};
    if (localStorageItems) {
      const decrypting =
        this.cryptoService.decryptedLocalStorage(localStorageItems);
      decryptingRecord = JSON.parse(decrypting);
    }
    const data = JSON.stringify({ ...decryptingRecord, [key]: value });
    const encrypting = this.cryptoService.encryptedLocalStorage(data);
    localStorage.setItem('data', encrypting);
  };
  decryptLocalStorageData = () => {
    const value = localStorage.getItem('data');
    if (value) {
      const decrypted = this.cryptoService.decryptedLocalStorage(value);
      return JSON.parse(decrypted);
    }
    return null;
  };
  getLocalStorageValue = (key) => {
    const data = this.decryptLocalStorageData();

    return data?.[key] ?? false;
  };
}
