import { Buffer } from "buffer";
const crypto = require("crypto-browserify");

export function createLocalStorageKeys(encryptionKey) {
  let finalKey = encryptionKey;
  let hash = crypto.createHash("sha256").update(finalKey, "utf8").digest("hex");

  let key = hash.substring(0, 32);
  let iv = hash.substring(32, 64).substring(0, 16);

  return { key, iv };
}
const encryptedLocalStorage = (text) => {
  const chatEncryptionSecretKey =
    "asdfghjklqwertyuiopzxcvbnmasdfgh@YOLOH.InSuRAnCE";
  const keyData = createLocalStorageKeys(chatEncryptionSecretKey);
  const iv = Buffer.from(keyData.iv, "utf8");
  const key = Buffer.from(keyData.key, "utf8");
  const cipher = crypto.createCipheriv("aes-256-gcm", key, iv);
  const encrypted = Buffer.concat([
    cipher.update(text, "utf8"),
    cipher.final(),
  ]);
  const tag = cipher.getAuthTag();
  return Buffer.concat([encrypted, tag]).toString("base64");
};

//decripting the local storage .
const decryptedLocalStorage = (encryptedText) => {
  const chatEncryptionSecretKey =
    "asdfghjklqwertyuiopzxcvbnmasdfgh@YOLOH.InSuRAnCE";
  const keyData = createLocalStorageKeys(chatEncryptionSecretKey);
  try {
    const encryptedData = Buffer.from(encryptedText, "base64");
    const tagLength = 16;
    const tag = encryptedData.slice(-tagLength);
    const encrypted = encryptedData.slice(0, -tagLength);
    const decipher = crypto.createDecipheriv(
      "aes-256-gcm",
      keyData.key,
      keyData.iv
    );
    decipher.setAuthTag(tag);
    const decrypted =
      decipher.update(encrypted, "utf8", "utf8") + decipher.final("utf8");
    return decrypted;
  } catch (error) {
    console.error("Decryption error:", error);
    return "";
  }
};

export const setLocalStorageRecord = (key, value) => {
  const localStorageItems = localStorage.getItem("data");
  let decryptingRecord = {};
  if (localStorageItems) {
    const decrypting = decryptedLocalStorage(localStorageItems);
    decryptingRecord = JSON.parse(decrypting);
  }
  const data = JSON.stringify({ ...decryptingRecord, [key]: value });
  const encrypting = encryptedLocalStorage(data);
  localStorage.setItem("data", encrypting);
};
const decryptLocalStorageData = () => {
  const value = localStorage.getItem("data");
  if (value) {
    const decrypted = decryptedLocalStorage(value);
    return JSON.parse(decrypted);
  }
  return null;
};
export const getLocalStorageValue = (key) => {
  const data = decryptLocalStorageData();
  return data?.[key] ?? false;
};
